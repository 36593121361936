<template>
  <div class="orders__status">
    <div class="status-badge" :class="`status-badge-${type}`">
      {{ name }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        validator(value) {
          return ['red', 'yellow', 'blue', 'green', 'grey'].includes(value);
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .status-badge {
    font: $fira-12;
    border-radius: 4px;
    padding: 4px 8px;
    min-width: 120px;
    max-width: min-content;
    text-align: center;
    text-wrap: nowrap;

    &-green {
      color: $white;
      background: $green-2;
    }

    &-yellow {
      color: $base;
      background: $yellow-2;
    }

    &-red {
      color: $white;
      background: $red-2;
    }

    &-grey {
      color: $white;
      background: $gray-silver-dark;
    }

    &-blue {
      color: #247eba;
      background: #e8f6ff;
    }
    &-darkBlue {
      color: $white;
      background: $blue-status;
    }

    &-lightred {
      color: rgba(240, 79, 79, 0.5);
      background: rgba(240, 79, 79, 0.2);
    }
  }
</style>
