<template>
  <div class="implementation__filter-container">
    <InputBlock title="Федеральный округ" class="implementation__input-item">
      <SelectMultiComponent
        v-model="filter.federalDistrictId"
        :options="getFederalDistrictList"
        placeholder="Выберите"
        classSelect="multiselect--rectangular visible-clear"
        valueProp="id"
        track-by="name"
        label="name"
      />
    </InputBlock>

    <InputBlock title="Субъект РФ" class="implementation__input-item">
      <SelectMultiComponent
        v-model="filter.regionRfId"
        :options="getRegionsListWithId"
        placeholder="Выберите"
        classSelect="multiselect--rectangular visible-clear"
        valueProp="id"
        track-by="name"
        label="name"
      />
    </InputBlock>

    <InputBlock title="Страна-партнёр" class="implementation__input-item">
      <SelectMultiComponent
        v-model="filter.partnerCountryId"
        :options="getPartnerCountrySearchIdList"
        placeholder="Выберите"
        classSelect="multiselect--rectangular visible-clear"
        valueProp="id"
        track-by="name"
        label="name"
      />
    </InputBlock>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import InputBlock from '@/common/components/redesigned/InputBlock.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';

  import { DEFAULT_IMPLEMENTATION_FILTER } from '../../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    components: {
      InputBlock,
      SelectMultiComponent,
    },
    emits: ['changeFilter'],
    data() {
      return {
        filter: { ...DEFAULT_IMPLEMENTATION_FILTER },
      };
    },

    mounted() {
      this.fetchRegions();
      this.getPartnerCountrySearchId();
      this.fetchFederalDistricts();
    },

    computed: {
      ...mapGetters({
        getRegionsListWithId: DIRECTIVES_GETTERS_NAME.getRegionsListWithId,
        getPartnerCountrySearchIdList: DIRECTIVES_GETTERS_NAME.getPartnerCountrySearchIdList,
        getFederalDistrictList: DIRECTIVES_GETTERS_NAME.getFederalDistrictList,
      }),
    },

    methods: {
      ...mapActions({
        fetchRegions: DIRECTIVES_ACTIONS_NAME.getRegions,
        fetchFederalDistricts: DIRECTIVES_ACTIONS_NAME.getFederalDistricts,
        getPartnerCountrySearchId: DIRECTIVES_ACTIONS_NAME.getPartnerCountrySearchId,
      }),
    },

    watch: {
      filter: {
        handler(value) {
          this.$emit('changeFilter', value);
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .implementation {
    &__filter-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      @media (max-width: $laptop) {
      }
    }

    &__input-item {
      max-width: 416px;

      @media (max-width: $tablet) {
        max-width: 344px;
      }

      @media (max-width: $laptop) {
        max-width: 100%;
      }
    }
  }
</style>
