<template>
  <div class="implementation">
    <PageTitle title="Информация об&#160;осуществлении внешнеэкономических связей в&#160;истекшем году" />

    <div class="implementation__page-blocks container-page big">
      <ImplementationFilterBlock @changeFilter="changeFilter" />

      <section>
        <div class="implementation__title-container">
          <h2 class="implementation__title">Информация о подписанных соглашениях</h2>
          <ButtonComponent
            class="implementation__button"
            @click="exportAgreementsHistory"
            :disabled="isExportAgreementsProgress"
          >
            <IconComponent name="downloadBlue" />
            Скачать
          </ButtonComponent>
        </div>
        <div class="table-vertical-line">
          <table>
            <thead>
              <tr>
                <th class="column-48">№</th>
                <th class="column-200">Форма соглашения</th>
                <th class="column-352">Наименование</th>
                <th class="column-292">Сумма, руб</th>
                <th class="column-292">Статус реализации</th>
              </tr>
            </thead>
            <tbody v-if="isLoadingData">
              <td :colspan="5" class="implementation__no-data"><Loader /></td>
            </tbody>
            <tbody v-else-if="getAgreementsHistory.items.length">
              <tr v-for="(item, index) in getAgreementsHistory.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.form }}</td>
                <td>
                  {{ item.name }}
                </td>
                <td>{{ numberFormatter(item.sum, 'ru', 0) }}</td>
                <td>
                  <StatusBadge
                    :name="AGREEMENTS_HISTORY_STATUS[item.statusId]?.name"
                    :type="AGREEMENTS_HISTORY_STATUS[item.statusId]?.type"
                  />
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="5" class="implementation__no-data">Данные отсутствуют</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="implementation__more-button">
          <ShowMoreButton
            :loadingMore="isLoadingMoreAgreements"
            :currentPage="getAgreementsHistory.pageNumber"
            :pageCount="getAgreementsHistory.pagesTotal"
            @onClick="handleMoreAgreements"
          >
            Показать еще
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path
                d="M8.5 3.33334V12.6667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M3.83333 8H13.1667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </ShowMoreButton>
        </div>
      </section>

      <section>
        <div class="implementation__title-container">
          <h2 class="implementation__title">Основные мероприятия</h2>
          <ButtonComponent
            class="implementation__button"
            @click="exportEventsHistory"
            :disabled="isExportEventProgress"
          >
            <IconComponent name="downloadBlue" />
            Скачать
          </ButtonComponent>
        </div>
        <div class="table-vertical-line implementation__table">
          <table>
            <thead>
              <tr>
                <th class="column-48">№</th>
                <th class="column-568">Наименование мероприятия</th>
                <th class="column-max">Отрасль</th>
              </tr>
            </thead>
            <tbody v-if="isLoadingData">
              <td :colspan="5" class="implementation__no-data"><Loader /></td>
            </tbody>
            <tbody v-else-if="getEventsHistory.items.length">
              <tr v-for="(item, index) in getEventsHistory.items" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ item.name }}
                </td>
                <td>{{ item.industry }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td :colspan="4" class="implementation__no-data">Данные отсутствуют</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="implementation__more-button">
          <ShowMoreButton
            :loadingMore="isLoadingMoreEvents"
            :currentPage="getEventsHistory.pageNumber"
            :pageCount="getEventsHistory.pagesTotal"
            @onClick="handleMoreEvents"
          >
            Показать еще
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
              <path
                d="M8.5 3.33334V12.6667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
              <path
                d="M3.83333 8H13.1667"
                stroke="#01A39D"
                stroke-width="1.5"
                stroke-linecap="square"
                stroke-linejoin="round"
              />
            </svg>
          </ShowMoreButton>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import PageTitle from '@/common/components/redesigned/PageTitle.vue';
  import ShowMoreButton from '@/common/components/redesigned/ShowMoreButton.vue';
  import StatusBadge from '@/common/components/redesigned/StatusBadge.vue';
  import numberFormatter from '@/common/mixins/numberFormatter';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import Loader from '@/components/Loader';

  import ImplementationFilterBlock from '../components/implementation/ImplementationFilterBlock.vue';
  import { IMPLEMENTATION_GETTERS_NAME } from '../store/implementation/name';
  import { IMPLEMENTATION_ACTIONS_NAME } from '../store/implementation/name';
  import { AGREEMENTS_HISTORY_STATUS, DEFAULT_IMPLEMENTATION_FILTER } from '../utils/constants';

  import Links from '@/utils/links';

  export default {
    components: {
      PageTitle,
      ImplementationFilterBlock,
      ShowMoreButton,
      StatusBadge,
      ButtonComponent,
      IconComponent,
      Loader,
    },
    mixins: [numberFormatter],
    data() {
      return {
        eventsRequest: {
          pageSize: 5,
          pageNumber: 1,
        },
        agreementsRequest: {
          pageSize: 5,
          pageNumber: 1,
        },
        filter: { ...DEFAULT_IMPLEMENTATION_FILTER },
        isLoadingData: false,
        isLoadingMoreEvents: false,
        isLoadingMoreAgreements: false,
        isExportEventProgress: false,
        isExportAgreementsProgress: false,
        AGREEMENTS_HISTORY_STATUS,
      };
    },
    mounted() {
      this.fetchHistoryInfo();
    },
    computed: {
      ...mapGetters({
        getEventsHistory: IMPLEMENTATION_GETTERS_NAME.getEventsHistory,
        getAgreementsHistory: IMPLEMENTATION_GETTERS_NAME.getAgreementsHistory,
      }),
    },
    methods: {
      ...mapActions({
        fetchEventsHistory: IMPLEMENTATION_ACTIONS_NAME.fetchEventsHistory,
        fetchMoreEventsHistory: IMPLEMENTATION_ACTIONS_NAME.fetchMoreEventsHistory,
        fetchAgreementsHistory: IMPLEMENTATION_ACTIONS_NAME.fetchAgreementsHistory,
        fetchMoreAgreementsHistory: IMPLEMENTATION_ACTIONS_NAME.fetchMoreAgreementsHistory,
      }),

      changeFilter(filter) {
        this.filter = filter;
        this.eventsRequest.pageNumber = 1;
        this.agreementsRequest.pageNumber = 1;
        this.fetchHistoryInfo();
      },

      async fetchHistoryInfo() {
        this.isLoadingData = true;
        await this.fetchEventsHistory({ ...this.eventsRequest, ...this.filter });
        await this.fetchAgreementsHistory({ ...this.agreementsRequest, ...this.filter });
        this.isLoadingData = false;
      },

      async exportEventsHistory() {
        this.isExportEventProgress = true;
        await Utils.downloadFile(
          Links.ves.implementation.exportEvents,
          'Основные мероприятия в истекшем году.xlsx',
          this.filter,
          true,
        );
        this.isExportEventProgress = false;
      },
      async exportAgreementsHistory() {
        this.isExportAgreementsProgress = true;
        await Utils.downloadFile(
          Links.ves.implementation.exportAgreements,
          'Информация о подписанных соглашениях в истекшем году.xlsx',
          this.filter,
          true,
        );
        this.isExportAgreementsProgress = false;
      },
      async handleMoreEvents() {
        this.isLoadingMoreEvents = true;
        this.eventsRequest.pageNumber += 1;
        await this.fetchMoreEventsHistory({ ...this.eventsRequest, ...this.filter });
        this.isLoadingMoreEvents = false;
      },
      async handleMoreAgreements() {
        this.isLoadingMoreAgreements = true;
        this.agreementsRequest.pageNumber += 1;
        await this.fetchMoreAgreementsHistory({ ...this.agreementsRequest, ...this.filter });
        this.isLoadingMoreAgreements = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  @import '@/assets/theme/default/table/table-vertical-line.scss';

  .implementation {
    &__page-blocks {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
    &__title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $mobile) {
        flex-direction: column;
        align-items: start;
        gap: 20px;
      }
    }

    &__title {
      font: $fira-24-M;
      justify-items: start;
    }

    &__button {
      display: flex;
      justify-content: center;
      gap: 4px;

      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    &__no-data {
      font: $fira-16;
      text-align: center;
      margin-top: 20px;
    }

    &__more-button {
      margin-top: 24px;
    }
  }
</style>
